import { IpressoActivity } from '../server/ipresso/map/activity';

enum IPRESSO_SOURCES {
  'NEWSLETTER' = 'newsletter',
  'MAGAZINE' = 'magazine',
  'WEBINAR' = 'webinar',
  'REGISTER' = 'register',
  'PROFILE' = 'profile',
  'CONTACT' = 'contact',
}

const IPRESSO_DEV_USER_TAG = 'DEV-USER';
const IPRESSO_PROD_USER_TAG = 'TRANSINFO-USER';
const IPRESSO_NEWSLETTER_USER_TAG = 'NEWSLETTER-USER';

const IPRESSO_CATEGORY_ID = {
  pl: {
    driver: '58',
    logistician: '57',
    carrier: '56',
    forwarder: '55',
    shipper: '54',
  },
  en: {
    driver: '77',
    logistician: '73',
    carrier: '69',
    forwarder: '65',
    shipper: '61',
  },
  es: {
    driver: '77', // english
    logistician: '73', // english
    carrier: '69', // english
    forwarder: '65', // english
    shipper: '61', // english
  },
  de: {
    driver: '59',
    logistician: '63',
    carrier: '67',
    forwarder: '71',
    shipper: '75',
  },
  lt: {
    driver: '60',
    logistician: '64',
    carrier: '68',
    forwarder: '72',
    shipper: '76',
  },
  ua: {
    driver: '62',
    logistician: '66',
    carrier: '70',
    forwarder: '74',
    shipper: '78',
  },
  ru: {
    driver: '77', // english
    logistician: '73', // english
    carrier: '69', // english
    forwarder: '65', // english
    shipper: '61', // english
  },
  hu: {
    driver: '77', // english
    logistician: '73', // english
    carrier: '69', // english
    forwarder: '65', // english
    shipper: '61', // english
  },
  ro: {
    driver: '77', // english
    logistician: '73', // english
    carrier: '69', // english
    forwarder: '65', // english
    shipper: '61', // english
  }
};

const IPRESSO_AGREEMENT_ID = {
  pl: {
    marketing: '1',
    commercialInside: '6',
    commercialOutside: '7',
    newsletter: '3',
  },
  de: {
    marketing: '8',
    commercialInside: '16',
    commercialOutside: '23',
    newsletter: '3',
  },
  en: {
    marketing: '13',
    commercialInside: '17',
    commercialOutside: '28',
    newsletter: '3',
  },
  es: {
    marketing: '29 ',
    commercialInside: '30',
    commercialOutside: '31',
    newsletter: '3',
  },
  ro: {
    marketing: '10',
    commercialInside: '18',
    commercialOutside: '26',
    newsletter: '3',
  },
  lt: {
    marketing: '9',
    commercialInside: '19',
    commercialOutside: '24',
    newsletter: '3',
  },
  ru: {
    marketing: '11',
    commercialInside: '20',
    commercialOutside: '27',
    newsletter: '3',
  },
  ua: {
    marketing: '14',
    commercialInside: '15',
    commercialOutside: '22',
    newsletter: '3',
  },
  hu: {
    marketing: '12',
    commercialInside: '21',
    commercialOutside: '25',
    newsletter: '3',
  },
};

const IPRESSO_CATEGORY_LANG = Object.keys(IPRESSO_CATEGORY_ID).map((key) => key);

const IPRESSO_AGREEMENT_LANG = Object.keys(IPRESSO_AGREEMENT_ID).map((key) => key);

export enum IpressoActivityKeys {
  api_edit = 'api_edit',
}

enum IpressoParameterKeys {
  change_source = 'change_source',
  change_date = 'change_date',
  site_language = 'site_language',
  wordpress_id = 'wordpress_id',
}

const IPRESSO_ACTIVITY_ID: Record<keyof typeof IpressoActivityKeys, IpressoActivity> = {
  api_edit: {
    key: 'api_edit',
    parameters: ['change_source', 'change_date', 'site_language', 'wordpress_id'],
  },
};

export {
  IPRESSO_SOURCES,
  IPRESSO_DEV_USER_TAG,
  IPRESSO_PROD_USER_TAG,
  IPRESSO_NEWSLETTER_USER_TAG,
  IPRESSO_CATEGORY_ID,
  IPRESSO_AGREEMENT_ID,
  IPRESSO_CATEGORY_LANG,
  IPRESSO_AGREEMENT_LANG,
  IPRESSO_ACTIVITY_ID,
  IpressoParameterKeys,
};
